import React from "react";
import Layout from "components/Layout";
import {contentfulRenderer} from "../utils/contentfulRenderer";
import {PropsWithTranslationSheet} from "../i18n/TranslationSheet";

const PrivacyPolicy: React.FC<PropsWithTranslationSheet & {pageContext: {content: any}}> = ({
    pageContext: {t, content}
}) => {
    return (
        <Layout
            t={t}
            title={t.PRIVACY_POLICY_TITLE}
            metaDescription={t.PRIVACY_POLICY_META_DESCRIPTION}
            noIndex
        >
            <main className={"prose m-auto pt-10"}>{contentfulRenderer(content)}</main>
        </Layout>
    );
};

export default PrivacyPolicy;
